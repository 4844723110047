/* Colors*/
$black: #000;
$white: #fff;
$darkgrey: #1a1a1a;
$grey: #999;
$lightgrey: #dadada;
$brown: #8D5852;
$darkbrown: #522917;
$green: #4db6ac;
$yellow: #ffcc5c;
$darkred: #bf4e4e;
$lightblue: #8c9ba3;
$darkblue: #559ab3;
$decal: $yellow;
$time: 0; 


/* Mixins 84cdc2 */
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}


/* generic transform */
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

/* rotate */
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

/* Animation mixin */
@mixin animation($animation, $duration, $timing, $iteration ) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: inherit;
  -webkit-animation-timing-function: $timing;
  -webkit-animation-iteration-count: $iteration;
 
  -moz-animation-name: $animation;
  -moz-animation-duration: inherit;
  -moz-animation-timing-function: $timing;
  -moz-animation-iteration-count: $iteration;
 
  -o-animation-name: $animation;
  -o-animation-duration: inherit;
  -o-animation-timing-function: $timing;
  -o-animation-iteration-count: $iteration;

  animation-name: $animation;
  animation-duration: inherit;
  animation-timing-function: $timing;
  animation-iteration-count: $iteration;
}

/* Animations */
/* wheel */
@include keyframes(wheelspin){
  from{
    transform: rotate(0deg);
  }
  
  to{
    transform: rotate(360deg);
  }
}

 /* floor */
@include keyframes(floor){
  to { background-position-x: 100%; }
}

/* pedals */
@include keyframes(pedalstem-front){
  from{
    transform: rotate(0deg);
  }
  
  to{
    transform: rotate(360deg);
  }
}

@include keyframes(pedalstem-back){
  from{
    transform: rotate(-180deg);
  }
  
  to{
    transform: rotate(180deg);
  }
}

@include keyframes(pedalbase-front){
  from{
    transform: rotate(0deg);
  }
  
  to{
    transform: rotate(-360deg);
  }
}

@include keyframes(pedalbase-back){
  from{
    transform: rotate(180deg);
  }
  
  to{
    transform: rotate(-180deg);
  }
}

/*
  thigh
  @include transform(rotate(20deg));
  @include transform(rotate(42deg));
  @include transform(rotate(70deg));
  @include transform(rotate(74deg));
  @include transform(rotate(50deg));
  @include transform(rotate(35deg));
  @include transform(rotate(17deg));
  @include transform(rotate(4deg));
  @include transform(rotate(-2deg));
  @include transform(rotate(5deg));
  @include transform(rotate(20deg));

  //calf
  @include transform(rotate(-5deg));
  @include transform(rotate(-34deg));
  @include transform(rotate(-70deg));
  @include transform(rotate(-68deg));
  @include transform(rotate(-20deg));
  @include transform(rotate(12deg));
  @include transform(rotate(34deg));
  @include transform(rotate(44deg));
  @include transform(rotate(40deg));
  @include transform(rotate(19deg));
  @include transform(rotate(-5deg));
 
  //foot
  @include transform(rotate(5deg));
  @include transform(rotate(8deg));
  @include transform(rotate(16deg));
  @include transform(rotate(14deg));
  @include transform(rotate(11deg));
  @include transform(rotate(9deg));
  @include transform(rotate(10deg));
  @include transform(rotate(10deg));
  @include transform(rotate(4deg));
  @include transform(rotate(-4deg));
  @include transform(rotate(5deg));
*/

@include keyframes(right-thigh){
  0%{ transform: rotate(20deg); }
  10%{ transform: rotate(42deg); }
  20%{ transform: rotate(70deg); }
  30%{ transform: rotate(74deg); }
  40%{ transform: rotate(50deg); }
  50%{ transform: rotate(35deg); }
  60%{ transform: rotate(17deg); }
  70%{ transform: rotate(4deg); }
  80%{ transform: rotate(-2deg); }
  90%{ transform: rotate(5deg); }
  100%{ transform: rotate(20deg); }
}

@include keyframes(right-calf){
  0%{ transform: rotate(-5deg); }
  10%{ transform: rotate(-34deg); }
  20%{ transform: rotate(-70deg); }
  30%{ transform: rotate(-68deg); }
  40%{ transform: rotate(-20deg); }
  50%{ transform: rotate(12deg); }
  60%{ transform: rotate(34deg); }
  70%{ transform: rotate(44deg); }
  80%{ transform: rotate(40deg); }
  90%{ transform: rotate(19deg); }
  100%{ transform: rotate(-5deg); }
}

@include keyframes(right-foot){
  0%{ transform: rotate(5deg); }
  10%{ transform: rotate(10deg); }
  20%{ transform: rotate(15deg); }
  30%{ transform: rotate(18deg); }
  40%{ transform: rotate(20deg); }
  50%{ transform: rotate(9deg); }
  60%{ transform: rotate(10deg); }
  70%{ transform: rotate(10deg); }
  80%{ transform: rotate(4deg); }
  90%{ transform: rotate(-4deg); }
  100%{ transform: rotate(5deg); }
}

@include keyframes(left-thigh){
  0%{ transform: rotate(35deg); }

  10%{ transform: rotate(17deg); }
  20%{ transform: rotate(4deg); }
  30%{ transform: rotate(-2deg); }
  40%{ transform: rotate(5deg); }
  50%{ transform: rotate(20deg); }
  
  60%{ transform: rotate(42deg); }
  70%{ transform: rotate(70deg); }
  80%{ transform: rotate(74deg); }
  90%{ transform: rotate(50deg); }
  100%{ transform: rotate(35deg); }
}

@include keyframes(left-calf){
  0%{ transform: rotate(12deg); }
 
  10%{ transform: rotate(34deg); }
  20%{ transform: rotate(44deg); }
  30%{ transform: rotate(40deg); }
  40%{ transform: rotate(19deg); }
  50%{ transform: rotate(-5deg); }

  60%{ transform: rotate(-34deg); }
  70%{ transform: rotate(-70deg); }
  80%{ transform: rotate(-68deg); }
  90%{ transform: rotate(-20deg); }
  100%{ transform: rotate(12deg); }

}

@include keyframes(left-foot){
  0%{ transform: rotate(9deg); }
  
  10%{ transform: rotate(10deg); }
  20%{ transform: rotate(10deg); }
  30%{ transform: rotate(4deg); }
  40%{ transform: rotate(-4deg); }
  50%{ transform: rotate(5deg); }

  60%{ transform: rotate(10deg); }
  70%{ transform: rotate(15deg); }
  80%{ transform: rotate(18deg); }
  90%{ transform: rotate(20deg); }
  100%{ transform: rotate(9deg); }

}

 /* bike */
@include keyframes(riding){
  0% { transform: translateX(-0.1%); }
  50% { transform: translateX(0.1%); }
  100% { transform: translateX(-0.1%); }
}

@include keyframes(rider-pulse){
  0%{ transform: rotate(-1deg); }
  60%{ transform: rotate(-1.3deg); }
  100%{ transform: rotate(-1deg); }
}

/* road surface */
@include keyframes(surface-move){
  0% { background-position: 100% 0px; }
  100% { background-position: -2000% 0px; }
}


/* The bike */
.bike {
  width: 760px;
  height: 600px;
  margin: 370px 0 0 calc(50% - 380px);
  padding:0px; 

  &.cadence40 { -webkit-animation-duration: 1.5s; -o-animation-duration: 1.5s; -moz-animation-duration: 1.5s; animation-duration: 1.5s; }
  &.cadence50 { -webkit-animation-duration: 1.2s; -o-animation-duration: 1.2s; -moz-animation-duration: 1.2s; animation-duration: 1.2s; }
  &.cadence60 { -webkit-animation-duration: 1.0s; -o-animation-duration: 1.0s; -moz-animation-duration: 1.0s; animation-duration: 1.0s; }
  &.cadence70 { -webkit-animation-duration: 0.857s; -o-animation-duration: 0.857s; -moz-animation-duration: 0.857s; animation-duration: 0.857s; }
  &.cadence80 { -webkit-animation-duration: 0.75s; -o-animation-duration: 0.75s; -moz-animation-duration: 0.75s; animation-duration: 0.75s; }
  &.cadence90 { -webkit-animation-duration: 0.667s; -o-animation-duration: 0.667s; -moz-animation-duration: 0.667s; animation-duration: 0.667s; }
  &.cadence100 { -webkit-animation-duration: 0.6s; -o-animation-duration: 0.6s; -moz-animation-duration: 0.6s; animation-duration: 0.6s; }
  &.cadence110 { -webkit-animation-duration: 0.545s; -o-animation-duration: 0.545s; -moz-animation-duration: 0.545s; animation-duration: 0.545s; }
  &.cadence120 { -webkit-animation-duration: 0.5s; -o-animation-duration: 0.5s; -moz-animation-duration: 0.5s; animation-duration: 0.5s; }
  &.cadence130 { -webkit-animation-duration: 0.462s; -o-animation-duration: 0.462s; -moz-animation-duration: 0.462s; animation-duration: 0.462s; }
  &.cadence140 { -webkit-animation-duration: 0.429s; -o-animation-duration: 0.429s; -moz-animation-duration: 0.429s; animation-duration: 0.429s; }

  * {
    -webkit-animation-duration: inherit;
    -o-animation-duration: inherit;
    -moz-animation-duration: inherit;
    animation-duration: inherit;
  }

  .part{
    position: absolute;
  }

  .frameSet{
     width:630px; 
     height:400px;
     margin:100px 0 0 45px;
     background-image: url("https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/03/F62_allround.png");
  }

  .frame{
    .bar{
      width: 25px;
      position: absolute;
      
      &.leftTop{
        width: 14px;
        height: 240px;
        margin: 198px 0 0 185px;
        border-radius:7px;
        @include transform(rotate(52deg));
      }
      
      &.leftBottom{
        width: 28px;
        margin: 284px 0 0 200px;
        height: 250px;
        border-radius:14px;
        @include transform(rotate(-82deg));
      }
      
      &.left{
        height: 267px;
        margin: 176px 0 0 286px;
        border-radius:10px;
        @include transform(rotate(-15deg));
      }
      
      &.top{
        width:20px;
        height: 312px;
        margin: 22px 0 0 405px;
        @include transform(rotate(82deg));
      }
      
      &.bottom{
        width:30px;
        height: 350px;
        margin: 125px 0 0 430px;
        @include transform(rotate(45deg));
      }
      
      &.right{
        width:28px;
        height:280px;
        margin: 136px 0 0 592px;
        border-radius:0 0 14px 14px;      
        @include transform(rotate(-21deg));
      }
    }
  }

  .saddle{
    margin-left: 216px;
    position: absolute;
    
    .sitHere{
      width: 160px; height: 39px;     
      margin: 55px 0 0 -86px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -10px;
    }  
    .saddlepen{
      width: 18px;
      height: 200px;
      margin: -20px 0 0 17px;
      background: $darkgrey;
      @include transform(rotate(-17deg));
    }
  }

  .wheel{
    width: 350px;
    height: 350px;
    margin-top: 220px;
    border-radius: 100%;
    border: 0px;
    box-shadow: 0 0 0 12px $darkgrey inset, 0 0 0 12px solid #333; 
    /* 10 ~ 40, 110 */ 
      
    @include animation(wheelspin, $time , linear, infinite);
    
    &.left{ margin-left: -76px; }
    &.right{ margin-left: 480px; }
    
    .sticker {
      width:20px; 
      height:5px; 
      margin:10px 0 0 165px;
      background:$yellow;
      position: absolute;
    }

    .spoke{
      width: 2px;
      height: 350px;
      margin: 0px 0 0 174px;
      position: absolute;
      background: $darkgrey;
      
      &:nth-child(2){
        @include transform(rotate(30deg));
      }
      
      &:nth-child(3){
        @include transform(rotate(60deg));
      }
      
      &:nth-child(4){
        @include transform(rotate(90deg));
      }
      
      &:nth-child(5){
        @include transform(rotate(120deg));
      }
      
      &:nth-child(6){
        @include transform(rotate(150deg));
      }
      
      &:nth-child(7){
        @include transform(rotate(180deg));
      }
      
      &:nth-child(8){
        background:$darkgrey;
        @include transform(rotate(210deg));
      }
    }
  }

  .axis{
      width: 16px;
      height: 16px;
      margin-top: 273px;
      position: absolute;
      background: $yellow;
      border-radius: 50%;
      &.left{
        margin: 388px 0 0 90px;     
      }
      &.right{
        margin: 388px 0 0 648px;
      } 
  }

  .handlebar{
    margin:50px 0 0 50px; 
    
    .forkStem{
      position: absolute;
      width: 20px;
      height: 55px;
      margin: 20px 0 0 384px;
      background: $darkgrey;
      @include transform(rotate(-19deg));
    
      .handleStem {
        width: 26px;
        height: 24px;
        margin-left: -2px; /*377~388*/
        margin-top: -5px; /*56~76*/
        border-radius:2px;
        background: $darkgrey;
    
        /*** �ㅼ젣 �ㅽ뀥 ***/
        .prehandle {
          position: absolute;

          width: 20px;
          height: 52px; 
          margin: 0px 0 0 28px;
          @include transform(rotate(107deg));
          background: $darkgrey;

          .handle{
            width: 130px; height: 130px;
            margin: 0px 0 0 28px;

            background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -290px;
            @include transform(rotate(-48deg));
          }    
        }
      }  
    }
  }

  .pedals{
    margin-left: 224px;
    margin-top: 290px;
   
    * {
      position: absolute; 
    }
    
    .inside{
      width: 220px; height: 220px;
      margin: 42px 0 0 -10px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -1762px;
      @include animation(pedalstem-front, $time , linear, infinite);    
    }
    
    .outside{
      width: 220px; height: 220px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -1522px;    
      margin: 42px 0 0 -10px;
      @include animation(pedalstem-front, $time , linear, infinite);       
    }
    
    .pedalstem{
      width: 122px;
      height: 45px;

      margin: 120px 0 0 80px;
      border-radius: 10px;
      transform-origin: 8px 9px;     
    }
  }


/*** bike type ***/
  &.allround {
    .axis { background: $yellow; }

    .handlebar{   
      .forkStem{
        width: 20px;
      }
    }

    .frameSet{
       margin:90px 0 0 46px;
       width: 630px; height: 400px;
       background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -2770px;
    }
  }

  &.aero {
    .axis { background: $darkred; }

    .saddle{
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        width: 28px; 
        border-radius:5px 30px 0 0;
        margin: -20px 0 0 11px;
      }
    }

    .handlebar{   
      .forkStem{
        border-radius:15px 5px 0 0;
        width: 28px;
        
        .handleStem{
          width: 26px;
          height: 35px;
          border-radius:8px 5px 0 0;
  
          .prehandle {
            width: 21px;
          }
        }
      }
    }

    .frameSet{
      width: 640px; height: 400px;
      margin:88px 0 0 43px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -2350px;
    }
  }

  &.endurance {
    .axis { 
      background: $darkblue; 
      &.left{
        margin: 388px 0 0 86px;     
      }
      &.right{
        margin: 388px 0 0 650px;
      } 
    }

    .saddle{
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        margin: -20px 0 0 24px;
      }
    }
    .wheel{   
      &.left{ margin-left: -82px; }
      &.right{ margin-left: 482px; }
    }

    .handlebar{   
      .forkStem{
      }
    }

    .frameSet{
      width:640px; height:400px;
      margin:90px 0 0 46px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -3190px;    
    }
  }


  &.tt {
    .pedals{
      margin-left: 240px;
      margin-top: 288px;
    }  

    .axis {       
      display:none;
      &.left{
        margin: 388px 0 0 86px;     
      }
      &.right{
        margin: 388px 0 0 645px;
      } 
    }

    .saddle{
      .sitHere{
        margin: 42px 0 0 -44px;
      }  
      .saddlepen{
        display:none;
        margin: -20px 0 0 24px;
      }
    }
    .wheel{   
      &.left{ margin-left: -76px; }
      &.right{ margin-left: 483px; }
    }

    .handlebar{   
      display:none;
    }

    .frameSet{
      width:800px; height:440px;
      margin: 40px 0 0 34px;
      background: url('https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/08/F76_bike_frames_v2.png') 0px 0px;    
    }

    .rider {
      margin:-338px 0 0 146px;
      @include transform(rotate(-1deg));                          
    
      .upperBody {
        margin:86px 0 0 106px; 
        @include transform(rotate(23deg));     
  
        .helmet {
          margin:8px 0 0 242px; 
          @include transform(rotate(-4deg));     
        }  
  
        .shoulder {
          margin:98px 0 0 246px; 
          @include transform(rotate(-19deg));     
   
          .arm {
            margin:103px 0 0 102px; 
            @include transform(rotate(-38deg));         
          }
  
          .aeroBarBottom {
            display:none;
          }
        }
      }
    }

    .backgroundLeg {
      margin:-338px 0 0 140px;
    }           
  }
  
  &.hybrid {
    .axis { 
      background: $grey; 
      &.left{
        margin: 388px 0 0 86px;     
      }
      &.right{
        margin: 388px 0 0 650px;
      } 
    }

    .saddle{
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        margin: -20px 0 0 24px;
      }
    }
    .wheel{   
      &.left{ margin-left: -82px; }
      &.right{ margin-left: 482px; }
    }

    .handlebar{
      .forkStem {  
        .handleStem{
          .prehandle {
            height: 52px; 
            margin: -12px 0 0 40px; 
            .handle{         
               width:30px; height:30px; background:#333333; 
               border-radius:15px;  
               margin-left:-5px;
               margin-top:-10px;
            }    
          }
        }  
      }
    }

    .frameSet{
      width:800px; height:440px;
      margin: 96px 0 0 20px;
      background: url('https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/08/F76_bike_frames_v2.png') 0px -500px;    
    }
  }

  &.mtb {
    .axis { 
      display:none;
    }

    .saddle{
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        margin: -10px 0 0 14px;
      }
    }
    .wheel{   
      &.left{ margin-left: -100px; }
      &.right{ margin-left: 512px; }
    }

    .handlebar{
      display:none;
    }

    .frameSet{
      width:800px; height:500px;
      margin: 26px 0 0 20px;
      background: url('https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/08/F76_bike_frames_v2.png') 0px -950px;    
    }
  }

  
  &.cruisor {
    .axis { 
      display:none;
    }

    .saddle{
      display:none;
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        margin: -10px 0 0 14px;
      }
    }
    .wheel {   
      zoom: 0.91;
      &.left{ margin: 290px 0 0 -92px; }
      &.right{ margin: 290px 0 0 566px; }
    }

    .handlebar{
      display:none;
    }

    .frameSet{
      zoom: 1.1;
      width:800px; height:500px;
      margin: -36px 0 0 -110px;
      background: url('https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/08/F76_bike_frames_v2.png') 0px -1500px;    
    }

    .rider {
      .lowerBody {
        .rightLeg {}
      }
      .upperBody {
        margin:7px 0 0 -10px; 
        @include transform(rotate(-22deg));     
  
        .shoulder {
          margin:96px 0 0 180px; 
          @include transform(rotate(26deg));     
   
          .arm {
            margin:140px 0 0 87px; 
            @include transform(rotate(-12deg));         
          }
        }
      }
    }

    .pedals{

      .inside {
      }
      
      .outside {
        z-index:0;
        margin:35px 0 0 6px;
      }
    }
  }

  &.minivelo {
    .axis { 
      display:none;
    }

    .saddle{
      display:none;
      .sitHere{
        margin: 55px 0 0 -86px;
      }  
      .saddlepen{
        margin: -10px 0 0 14px;
      }
    }
    .wheel {   
      zoom: 0.67;
      box-shadow: 0 0 0 120px $darkgrey inset, 0 0 0 120px solid #333; 
      &.left{ margin: 520px 0 0 -48px; }
      &.right{ margin: 520px 0 0 820px; }
    }

    .handlebar{
      display:none;
    }

    .frameSet{
      zoom: 1.03;
      width:800px; height:520px;
      margin: -24px 0 0 -60px;
      background: url('https://rest.riduck.com/wp-content/uploads/mangboard/2022/08/08/F76_bike_frames_v2.png') 0px -2000px;    
    }

    .rider {
      .lowerBody {
        .rightLeg {}
      }
      .upperBody {
        margin:7px 0 0 -10px; 
        @include transform(rotate(-22deg));     
  
        .shoulder {
          margin:96px 0 0 180px; 
          @include transform(rotate(26deg));     
   
          .arm {
            margin:140px 0 0 87px; 
            @include transform(rotate(-6deg));         
          }
        }
      }
    }

    .pedals{
      .inside {}     
      .outside {}
    }
  }

  &.trainer {
    .axis {
      width: 16px;
      height: 16px;
      margin-top: 273px;
      position: absolute;
      background: $yellow;
      border-radius: 50%;
      &.left {
        zoom: 0.97;
        width: 500px;
        height: 400px; 
        margin: 280px 0 0 -108px;
        background: url('https://riduck.com/images/rider/bike_frames.png') 0px -2550px;    
      }
      &.right {
        margin: 388px 0 0 648px;
      } 
    }

    .wheel {    
      &.left { 
        margin: 334px 0 0 46px; 
        @include animation(wheelspin, $time , linear, infinite);     
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background:#cccccc;   
        box-shadow: none; 
        .sticker {
          display:none;
        }
        .spoke {
          display:none;
        }
      }
      &.right {
        @include animation(wheelspin, 0 , linear, 1);     
      } 
    }

    .handlebar {   
      .forkStem {
        width: 20px;
      }
    }

    .frameSet {
       margin:90px 0 0 46px;
       width: 630px; height: 400px;
       background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -2770px;
    }
  }  
}

.bike {
  .pedals{
    .outside{
      @include transform(rotate(360deg));   
    }
  }
}

.rider {
  width: 600px; 
  height: 800px;
  margin:-332px 0 0 110px;
  @include transform(rotate(-1deg));                          
  @include animation(rider-pulse, $time, linear, infinite);
  position: absolute;
  
  * {
    position: absolute;
  }
 
  .lowerBody {
    .bottom {
      width: 144px; height: 123px;
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -143px;
      margin:287px 0 0 50px;     

      .rightThigh {
        position:absolute; 
        width: 446px; height: 150px;
        margin:5px 0 0 -130px; 

        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -1117px;
        @include animation(right-thigh, $time , linear, infinite); 
      
        .rightCalf {
          width: 80px; height: 500px;
          margin:-125px 0 0 375px; 
          background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -4130px;

          @include animation(right-calf, $time , linear, infinite);            

          .rightFoot {
            width: 240px; height: 150px;
            margin:379px 0 0 -65px; 

            background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -947px;
            @include animation(right-foot, $time , linear, infinite);            
          }
        }   
      }
    }
  }

  .upperBody {
    width: 410px; height: 328px;
    background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -2002px;
    margin:22px 0 0 22px; 
    @include transform(rotate(-12deg));     
  
    .helmet {
      width: 200px; height: 165px;
      margin:0px 0 0 245px; 
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -4830px;   
    
      &.aero { 
        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -4650px;   
      }

      &.road { 
        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -4830px;   
      }

      &.tt { 
        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -5010px;   
      }
    }

    .shoulder {
      width: 134px; height: 215px;
      margin:120px 0 0 202px; 
      background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -1287px;
  
      @include transform(rotate(10deg));     
     
      .arm {
        width: 201px; height: 147px;
        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -440px;
        margin:155px 0 0 78px; 
        @include transform(rotate(6deg));        
      }
    }    
  }
}    

/***** rimHeight classes *****/




/***** pose classes *****/

/* pose tops - 6 degree stem */
.tops {
  .handlebar{
    .forkStem {  
      height: 50px;
      margin: 16px 0 0 478px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 40px; 
          margin: -7px 0 0 34px;
          @include transform(rotate(96deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:15px 0 0 12px; 
      @include transform(rotate(-17deg));     

      .shoulder {
        margin:125px 0 0 206px; 
        @include transform(rotate(10deg));     
 
        .arm {
          margin:154px 0 0 75px; 
          @include transform(rotate(8deg));         
        }
      }
    }
  }
}

/* pose hoodsStem4 - 6 degree stem */
.hoodsStem4 {
  .handlebar{
    .forkStem {  
      height: 50px;
      margin: 16px 0 0 478px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 40px; 
          margin: -7px 0 0 34px;
          @include transform(rotate(96deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:27px 0 0 30px; 
      @include transform(rotate(-10deg));     

      .shoulder {
        margin:123px 0 0 212px; 
        @include transform(rotate(6deg));     
 
        .arm {
          margin:140px 0 0 77px; 
          @include transform(rotate(2deg));         
        }
      }
    }
  }
}


/* pose hoodsStem2 - 10 degree stem */
.hoodsStem2 {
  .handlebar{
    .forkStem {  
      height: 40px;
      margin: 26px 0 0 479px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 48px; 
          margin: -10px 0 0 36px;
          @include transform(rotate(100deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:32px 0 0 40px; 
      @include transform(rotate(-6deg));     

      .shoulder {
        margin:126px 0 0 218px; 
        @include transform(rotate(4deg));     
 
        .arm {
          margin:136px 0 0 78px; 
          @include transform(rotate(-2deg));         
        }
      }
    }
  }
}

/* pose hoodsStem0 - 17 degree stem */
.hoodsStem0 {
  .handlebar{
    .forkStem {  
      height: 0px;
      margin: 40px 0 0 476px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 62px; 
          margin: -12px 0 0 40px;
          @include transform(rotate(106deg));
          .handle{         
            margin: -100px 0 0 -26px;
            @include transform(rotate(-80deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:44px 0 0 55px; 
      @include transform(rotate(0deg));     

      .shoulder {
        margin:126px 0 0 233px; 
        @include transform(rotate(-4deg));     
 
        .arm {
          margin:138px 0 0 75px; 
          @include transform(rotate(4deg));         
        }
      }
    }
  }
}

/* pose drops - 10 degree stem */
.drops {
  .handlebar{
    .forkStem {  
      height: 40px;
      margin: 26px 0 0 479px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 48px; 
          margin: -10px 0 0 36px;
          @include transform(rotate(100deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:48px 0 0 62px; 
      @include transform(rotate(3deg));     

      .shoulder {
        margin:120px 0 0 225px; 
        @include transform(rotate(2deg));     
 
        .arm {
          margin:145px 0 0 69px; 
          @include transform(rotate(4deg));         
        }
      }
    }
  }
}

/* pose leverEnds - 10 degree stem */
.leverEnds {
  .handlebar{
    .forkStem {  
      height: 40px;
      margin: 26px 0 0 479px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 48px; 
          margin: -10px 0 0 36px;
          @include transform(rotate(100deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:66px 0 0 74px; 
      @include transform(rotate(11deg));     

      .shoulder {
        margin:100px 0 0 238px; 
        @include transform(rotate(-8deg));     
 
        .arm {
          margin:106px 0 0 83px; 
          @include transform(rotate(-29deg));         
        }
      }
    }
  }
}

/* pose AeroBar - 10 degree stem */
.aeroBars {
  .handlebar{
    .forkStem {  
      height: 40px;
      margin: 26px 0 0 479px;
      .handleStem{
        margin: 0px 0 0 -2px; 
        .prehandle {
          height: 48px; 
          margin: -10px 0 0 36px;
          @include transform(rotate(100deg));
          .handle{         
            margin: -95px 0 0 -21px;
            @include transform(rotate(-72deg));     
          }    
        }
      }  
    }
  }

  .rider {
    .lowerBody {
      .rightLeg {
      }
    }
    .upperBody {
      margin:60px 0 0 70px; 
      @include transform(rotate(8deg));     

      .helmet {
        margin:15px 0 0 252px; 
        @include transform(rotate(15deg));     
      }  

      .shoulder {
        margin:100px 0 0 238px; 
        @include transform(rotate(-10deg));     
 
        .arm {
          margin:100px 0 0 90px; 
          @include transform(rotate(-29deg));         
        }

        .aeroBarBottom {
          margin: 179px 0 0 100px;
          width: 188px; height: 58px;
          background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -69px;
          @include transform(rotate(-3deg));         
        }
      }
    }
  }
}


.backgroundLeg {
  width: 600px; 
  height: 800px;
  margin:-332px 0 0 110px;
  @include transform(rotate(-2deg)); /* -2 ~ 4 */                           
  position: absolute;

  * {
    position: absolute;
  }
 
  .lowerBody {
    .bottom {
      margin:288px 0 0 38px;   

      .leftThigh {
        position:absolute; 
        width: 446px; height: 150px;
        margin:5px 0 0 -130px; 
        background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -777px;

        @include animation(left-thigh, $time , linear, infinite); 
          
        .leftCalf {
          width: 80px; height: 500px;
          margin:-125px 0 0 375px; 
          background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -3610px;

          @include animation(left-calf, $time , linear, infinite);            

          .leftFoot {
            width: 240px; height: 150px;
            margin:379px 0 0 -65px; 
            background: url('https://riduck.com/images/rider/rider_sprites.png') -10px -607px;

            @include animation(left-foot, $time , linear, infinite);            
          }
        }
      }
    }
  }
}




/* Z-INDEXES */

.rider               { z-index: 14; }
.rightThigh          { z-index: 14;}

.pedalstem{ &.front  { z-index: 9; }}

.frame               { z-index: 7; }
.frameSet            { z-index: 7; }
.axis                { z-index: 6; }

.aeroFrame           { z-index: 7; }

.outside             { z-index: 7; }
.handlebar           { z-index: 9; }
.handle		     { z-index: 9; }

.sitHere             { z-index: 5; }
.saddle              { z-index: 5; }
.sticker             { z-index: 5; }
.wheel               { z-index: 4; }

.inside              { z-index: 2; }
.pedalstem{ &.back   { z-index: 1; }}

.backgroundLeg       { z-index: 0;}



.trackWood {margin-top:-45px; height:50px; background: url("https://riduck.com/images/bike/track_wood.png"); @include animation(surface-move, $time*0.25 , linear, infinite); }; 
.trackAsphalt {margin-top:-45px; height:50px; background: url("https://riduck.com/images/bike/track_asphalt.png"); @include animation(surface-move, $time*0.25 , linear, infinite); }; 
.asphalt {margin-top:-45px; height:50px; background: url("https://riduck.com/images/bike/asphalt.png"); @include animation(surface-move, $time*0.2, linear, infinite); }; 
.bikeRoad {margin-top:-45px; height:50px; background: url("https://riduck.com/images/bike/bike_road.png"); @include animation(surface-move, $time*0.25, linear, infinite); }; 
.roughConcrete {margin-top:-45px; height:50px; background: url("https://riduck.com/images/bike/rough_concrete.png"); @include animation(surface-move, $time*0.25, linear, infinite); }; 
