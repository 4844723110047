/* reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
body{
  background-color: #f4f6f8;
   height: 100%;
}

/* font */
.wrapper {
  width: 100%;
}

.gnb {
  width: 100%;
  height: 54px;
  background-color: #fff;
  border: 1px solid #ffffff;
  
}
.topBox {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.topLogo img {
  width: auto;
  height: 40px;
}
.mainSection {
  height: auto;
  background-color: #4db6ac;
 
  text-align: center;
  position: relative;
  overflow: hidden;
  padding:  30px 16px;
  margin-top: 5px;
}

.mainSectionTitle {
  font-family: "Recipekorea";
  color: #ffffff;
  text-align: center;  
  margin: 30px auto;
  font-size: 36px;
  max-width: 1000px;
  line-height: 48px;
}


.mainImg {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.tabletImg img {
  width: 750px;
  z-index: 17;
  position: relative;
  zoom:0.7
}
.phoneImg img {
  height: 450px;
  margin-top: 100px;
  z-index: 17;
  position: relative;
  zoom:0.7
}
.loginBtn {
  width: 230px;
  height: 90px;
  background-color: #ffffff;
  color: #4db6ac;
  font-size: 40px;
  font-weight: bold;
  border-radius: 50px;
  border: none;
  margin: 50px 0;
  position: relative;
  z-index: 30;
  cursor: pointer;
  zoom:0.7
}
.loginBtn:hover {
  background-color: #009586;
  color: #fff;
}
/* animal 전체영역 */
.mainBottom {
  display: flex;
  position: absolute;
  width: 80%;
  max-width: 2000px;
  margin: 0 10% 0;
  bottom: -5px;
  z-index: 25;
}
.mainBackGround{
  zoom:0.7
}
.sky{
  zoom:0.7
}
/* cloud keyframes*/
@keyframes left {
  0% {
    transform: translate(0%, 0);
  }
  100% {
    transform: translate(2000%, 0);
  }
}
@keyframes right {
  0% {
    transform: translate(0%, 0);
  }
  100% {
    transform: translate(-2000%, 0);
  }
}

/* cloud */
.sky {
  position: relative;
  z-index: 0;
}
.leftCloud1 {
  width: 460px;
  height: 150px;
  position: absolute;
  display: inline-flex;
  top: 40px;
  left: -300px;
  animation: left 400s infinite ease-in;
  z-index: 15;
}
.leftCloud2 {
  width: 255px;
  height: 105px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 300px;
  z-index: 15;
  animation: right 350s infinite ease-in;
  zoom: 1.5;
}
.leftCloud3 {
  width: 210px;
  height: 88px;
  display: inline-flex;
  position: absolute;
  top: 260px;
  left: -50px;
  z-index: 15;
  animation: left 400s infinite ease-in;
  zoom: 1.5;
}

.rightSun {
  width: 106px;
  height: 105px;
  position: absolute;
  top: 30px;
  right: 80px;
  z-index: 15;
  animation: right 420s infinite ease-in;
  zoom: 1.4;
}
.rightCloud1 {
  width: 41px;
  height: 20px;
  position: absolute;
  top: 120px;
  right: 150px;
  z-index: 15;
  zoom: 1.5;
  animation: right 250s infinite ease-in;
}
.rightCloud2 {
  width: 155px;
  height: 55px;
  position: absolute;
  top: 150px;
  right: 150px;
  z-index: 15;
  zoom: 1.45;
  animation: right 320s infinite ease-in;
}
.rightCloud3 {
  width: 162px;
  height: 84px;
  position: absolute;
  top: 220px;
  right: -20px;
  z-index: 15;
  zoom: 1.4;
  animation: right 400s infinite ease-in;
}

.rightCloud4 {
  width: 456px;
  height: 150px;
  position: absolute;
  top: 370px;
  right: 100px;
  z-index: 15;
  zoom: 1.1;
  animation: right 500s infinite ease-in;
}
.leftLand1 {
  width: 677px;
  height: 220px;
  position: absolute;
  bottom: 30px;
  left: -30px;
  z-index: 8;
  zoom: 1.5;
  opacity: 0.1;
}
.leftLand2 {
  width: 380px;
  height: 73px;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 70px;
  z-index: 7;
  zoom: 2.3;
  opacity: 1;
}
.leftTree1 {
  width: 79px;
  height: 125px;
  position: absolute;
  bottom: 235px;
  left: 50px;
  z-index: 8;
  zoom: 1.3;
}
.leftTree2 {
  width: 79px;
  height: 125px;
  position: absolute;
  bottom: 220px;
  left: 0px;
  z-index: 8;
  zoom: 1.3;
}

/* 왼쪽 land */
.rightLand1 {
  width: 463px;
  height: 117px;
  position: absolute;
  z-index: 8;
  bottom: 35px;
  right: -40px;
  zoom: 2.2;
  opacity: 0.2;
}
.rightLand2 {
  width: 124px;
  height: 53px;
  position: absolute;
  bottom: 0px;
  right: 230px;
  z-index: 7;
  zoom: 2.5;
}
.rightTree1 {
  width: 79px;
  height: 125px;
  position: absolute;
  z-index: 8;
  bottom: 170px;
  right: 80px;
  zoom: 1.3;
}
.rightTree2 {
  width: 79px;
  height: 125px;
  position: absolute;
  bottom: 140px;
  right: 20px;
  z-index: 8;
  zoom: 1.3;
}
.section1,
.section2,
.section3 {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 16px;
  text-align: center;

}

.sectionTitle {
  color: #000000;
  font-size: 36px;
  margin: 30px auto;
  font-weight: bold;
}

.subTitle {
  color: #666666;
  font-size: 40px;  
  font-size: 22px;
  line-height: 32pxs;
  margin-bottom: 32px;
}

.contentImg,
.imgBox {
  display: flex;
  justify-content: center;
}
.contents {
  margin: 20px 0;
  font-size: 16px;
  color: #666666;
  text-align: center;
  font-weight: 300;
}
.contentImg1 img,
.contentImg2 img,
.contentImg3 img{
width: 250px;
}
.contentImg1 img,
.contentImg2 img,
.contentImg3 img,
.img1 img,
.img2 img,
.img3 img {
  height: 330px;
  box-shadow: 0 10px 14px -10px #999999;
}
.contentImg2 img {
  margin: 0 30px;
}

.img2 img {
  margin: 0 30px 0 10px;
}

.pcStravaDesc img {
  display: inline-block;
  zoom: 0.4;
}
.phoneStravaDesc img {
  display: none;
}
.footer {
  background-color: #4db6ac;
  color: #ffffff;
  zoom:0.7

}
.footerInner {
  max-width: 1200px;
  margin: 0 auto;
}
.footerMenu {
  padding: 100px 0;
}
.footerMenuList {
  display: flex;
  justify-content: space-around;
}

.footerMenuTitle {
  font-size: 22px;
  font-weight: 500;
}

.footerSubMenu {
  padding-top: 30px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

.footerMenuTitle:hover,
.footerSubMenu li:hover {
  font-weight: bold;
}
.bottomSection2 {
  display: flex;
  justify-content: space-around;
}
.footerIcon img {
  zoom: 1.2;
  cursor: pointer;
  margin-right: 360px;
}

.companyInfo p {
  font-size: 14px;
  line-height: 24px;
  text-align: right;
}
.companyInfo a {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}


@media screen and (min-width: 720px) and (max-width: 1280px){
  .wrapper{
    zoom: 0.8;
  }
  .mainBackGround{
    zoom:0.4
  }
}
@media screen and (min-width: 400px) and (max-width: 720px){
  .wrapper{
    zoom: 0.5
  }
  .mainBackGround{
    zoom:0.4
  }
  .tabletImg img {
    display: none;
  }
  .phoneImg img {
    margin: 0;
    height: 270px;
    width: 150px;
  }
  .gnb {
    height: 40px;
  }
  .topLogo img {
    width: auto;
    height: 25px;
    margin-top: 5px;
  }
  .pcStravaDesc img {
    display: none;
  }
  .phoneStravaDesc img {
    height: 300px;
    display: inline;
  }
}



@media all and (max-width: 400px) {
  .wrapper{
    zoom: 0.34
  }
  .mainBackGround{
    zoom:0.4
  }
  .tabletImg img {
    display: none;
  }
  .phoneImg img {
    margin: 0;
    height: 270px;
    width: 150px;
  }
  .gnb {
    height: 40px;
  }
  .topLogo img {
    width: auto;
    height: 25px;
    margin-top: 5px;
  }
  .pcStravaDesc img {
    display: none;
  }
  .phoneStravaDesc img {
    height: 300px;
    display: inline;
  }
}
