.animalsBottomWrap {
  zoom: 0.6;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cow {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.turtle {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.boar {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.camel {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.pig {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.lion {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.wolf {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}

.tiger {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.dog {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.deer {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.leopard {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.cat {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.horse {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}
.elephant {
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
}

.horseLanding {
  position: absolute;
  bottom: 10%;
  left: 10%;
  transform: scale(0.8);
  z-index: 25;
}

.elephantLanding {
  position: absolute;
  bottom: 10%;
  left: 10%;
  transform: scale(0.9);
  z-index: 25;
}

.boarLanding {
  position: absolute;
  right: 10%;
  bottom: 10%;
  transform: scale(0.8);
  z-index: 25;
}

.tigerLanding {
  transform: scale(0.8);
  z-index: 25;
}

.lionLanding {
  transform: scale(0.8);
  z-index: 25;
}

.roller {
  position: absolute;
  transform: scaleX(-1);
  width: 213px;
  height: 207px;
  bottom: 18px;
  right: 520px;
  z-index: 18;
  zoom: 1.2;
}

/* 동물 */
.bike {
  position: absolute;
  width: 210px;
  height: 154px;
  bottom: 26px;
  left: 460px;
  z-index: 20;
  zoom: 1.43;
}

/* 개별 동물 css */
@media screen and (max-height: 900px) {
  // .animalsBottomWrap {
  //   zoom: 0.36;
  // }
}

@media screen and (min-width: 1200px) and (max-width: 1680px) and (min-height: 800px) {
  // .animalsBottomWrap {
  //   zoom: 0.6;
  // }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  // .animalsBottomWrap {
  //   zoom: 0.46;
  // }

  .horseLanding {
    zoom: 0.8;
  }

  .elephantLanding {
    zoom: 0.8;
  }

  .boarLanding {
    zoom: 0.8;
    right: 0%;
  }

  .tigerLanding {
    zoom: 0.8;
  }

  .lionLanding {
    zoom: 0.8;
  }
}

@media screen and (min-width: 640px) and (max-width: 900px) {
  // .animalsBottomWrap {
  //   zoom: 0.3;
  // }

  .horseLanding {
    zoom: 0.8;
    left: 20%;
  }

  .elephantLanding {
    zoom: 0.8;
    left: 20%;
  }

  .boarLanding {
    zoom: 0.8;
    right: 20%;
  }

  .tigerLanding {
    zoom: 0.8;
  }

  .lionLanding {
    zoom: 0.8;
  }
}

@media screen and (min-width: 400px) and (max-width: 640px) {
  // .animalsBottomWrap {
  //   zoom: 0.18;
  // }

  .horseLanding {
    zoom: 0.7;
  }

  .elephantLanding {
    zoom: 0.7;
  }

  .boarLanding {
    zoom: 0.7;
  }

  .tigerLanding {
    zoom: 0.7;
  }

  .lionLanding {
    zoom: 0.7;
  }
}

@media all and (max-width: 400px) {
  // .animalsBottomWrap {
  //   zoom: 0.16;
  // }

  .horseLanding {
    zoom: 0.5;
  }

  .elephantLanding {
    zoom: 0.5;
  }

  .boarLanding {
    zoom: 0.5;
  }

  .tigerLanding {
    zoom: 0.5;
  }

  .lionLanding {
    zoom: 0.5;
  }
}

/* tooltip */
/* 출처: https://codepen.io/tutsplus/pen/WROvdG */
[tooltip] {
  position: relative;
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none;
  font-size: 1.1em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  text-align: center;

  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1.5ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^='up']::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^='up']::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^='up']::before,
[tooltip][flow^='up']::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* animation */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^='up']:hover::before,
[tooltip][flow^='up']:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}
